import { COMPLETED, PAST_DUE, PREV_LATE } from '../statuses';
import { NodeNames } from '../types';

export const getMilestoneStatus = (
  milestone,
  previousNode: any = false
): {
  status: 'COMPLETED' | 'PAST_DUE' | 'PREV_LATE' | undefined;
  pastDue: boolean;
  daysPastDue: number;
} => {
  const dueDate = new Date(milestone.dueOn);
  const now = new Date();
  const daysPastDue = Math.floor(
    (now.valueOf() - dueDate.valueOf()) / (1000 * 60 * 60 * 24)
  );
  const pastDue = daysPastDue > 0;

  let previousNodeIsLate = false;

  if (previousNode) {
    if (previousNode.__typename === NodeNames.milestone) {
      const prevNodeDueOn = new Date(previousNode.dueOn).valueOf();
      const prevNodeCompletedAt = new Date(previousNode.completedAt).valueOf();
      const prevNodeDaysLate = Math.floor(
        (prevNodeCompletedAt - prevNodeDueOn) / (1000 * 60 * 60 * 24)
      );
      previousNodeIsLate = prevNodeDaysLate > 0;
    }
  }

  const status = milestone.completedAt
    ? COMPLETED
    : pastDue
    ? PAST_DUE
    : previousNodeIsLate
    ? PREV_LATE
    : undefined;

  return { status, pastDue, daysPastDue };
};
