import { ReactElement } from 'react';
import { CanvasMilestone } from '@fis/data-graphql';
import { styled } from '@fis/util-theme';

import {
  DueDateBadge,
  PastDueBanner,
  PrevLateBanner,
  Title,
} from './components';

import { COMPLETED, PAST_DUE, PREV_LATE } from '../../../../../../statuses';
import { getMilestoneStatus, getStartOfDay } from '../../../../../../util';

const CardOuter = styled('div', {
  width: '100%',
  maxWidth: '1080px',
  margin: '0 auto',
  borderRadius: '$2',
  background: '$tintDark8',
  padding: '$2',

  '.lightMode &': {
    background: '$gray100',
  },

  variants: {
    current: {
      true: {
        background: '$tintDark16',

        '.lightMode &': {
          background: 'white',
          boxShadow: '$2',
        },
      },
    },
  },
});

const CardInner = styled('div', {
  display: 'flex',
  padding: '$5',
  width: '100%',
  height: '100%',
  gap: '$10',
});

const CardLeft = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flexGrow: 1,
});

const CardRight = styled('div', {
  display: 'flex',
  alignItems: 'start',
  gap: '$3',
});

const Size = styled('div', {
  fontSize: '$20',
  color: '$gray300',
  display: 'flex',
  alignItems: 'center',
  gap: '.25rem',
  flexWrap: 'wrap',

  '.lightMode &': {
    color: '$gray500',
  },
});

const SizeDot = styled('div', {
  backgroundColor: '$yellow500',
  borderRadius: '2px',
  width: '.25rem',
  height: '.625rem',

  '.lightMode &': {
    backgroundColor: '$yellow500',
  },

  // hides text unless screen reader
  lineHeight: '0',
  fontSize: '0',
}) as any;

const CurrentMilestoneLabel = styled('p', {
  color: '$lightblue400',
  fontSize: '$20',
  marginBottom: '$3',
  fontWeight: 'bold',

  '.lightMode &': {
    color: '$lightblue700',
  },
});

export const MilestoneNode = ({
  data,
  previousNode,
  allowBanner,
  current,
}: MilestoneNodeProps): ReactElement | null => {
  const { name, dueOn, completedAt, htmlUrl } = data;

  const { status, pastDue, daysPastDue } = getMilestoneStatus(
    data,
    previousNode
  );

  const CurrentText = current ? (
    <CurrentMilestoneLabel>Current Milestone</CurrentMilestoneLabel>
  ) : null;

  let Banner = null;

  if (pastDue) {
    Banner = current && allowBanner ? <PastDueBanner /> : null;
  } else {
    if (status === PREV_LATE && allowBanner && current) {
      const currentDaysLeft = Math.ceil(
        (getStartOfDay(dueOn).valueOf() - new Date().valueOf()) /
          (1000 * 60 * 60 * 24)
      );

      Banner = <PrevLateBanner daysLeft={currentDaysLeft} />;
    }
  }

  return (
    <CardOuter current={current}>
      {Banner}
      <CardInner>
        <CardLeft>
          {CurrentText}
          <Title htmlUrl={htmlUrl} status={status} completed={!!completedAt}>
            {name}
          </Title>
        </CardLeft>
        <CardRight>
          <DueDateBadge
            dueOn={dueOn}
            daysPastDue={daysPastDue}
            pastDue={pastDue}
            completedAt={completedAt}
            status={status}
          />
        </CardRight>
      </CardInner>
    </CardOuter>
  );
};

interface MilestoneNodeProps {
  data: CanvasMilestone;
  previousNode?: CanvasMilestone | null;
  allowBanner: boolean;
  current: boolean;
}

export default MilestoneNode;
