import { ReactElement } from 'react';
import PropTypes from 'prop-types';

import { CanvasMilestone } from '@fis/data-graphql';

import { MilestoneList } from './components';
import { MilestonesContext } from './contexts';
import { MilestonePropTypes, PaceChangePropTypes } from './types';

export const FeatureMilestonesCards = ({
  adminView,
  student,
}: PropTypes.InferProps<typeof propTypes>): ReactElement | null => {
  const milestones = student.milestones;
  if (milestones.length === 0) {
    return null;
  }

  return (
    <MilestonesContext.Provider value={{ studentUuid: null, adminView }}>
      <MilestoneList milestoneNodeList={milestones as CanvasMilestone[]} />
    </MilestonesContext.Provider>
  );
};

FeatureMilestonesCards.defaultProps = {
  adminView: false,
};

const propTypes = {
  adminView: PropTypes.bool.isRequired,
  student: {
    milestones: PropTypes.arrayOf(PropTypes.shape(MilestonePropTypes))
      .isRequired,
  },
};

FeatureMilestonesCards.propTypes = propTypes;

export default FeatureMilestonesCards;
