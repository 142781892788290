import { useGetCanvasMilestonesQuery } from '@fis/data-graphql';
import { FisLoadingIndicator } from '@fis/ui-loading-indicator';
import {
  NewFeatureTitleProgressCard,
  FeatureMilestonesCards,
} from '@fis/milestones';

export const FeatureMilestones = ({
  homePageVariant,
}: {
  homePageVariant: boolean;
}) => {
  const { data, error } = useGetCanvasMilestonesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const loading = !error && !data;
  const student = data?.student;

  if (error) {
    console.error('Error getting milestones schedule with pace changes', error);
    return null;
  } else if (loading) {
    return <FisLoadingIndicator />;
  }

  return (
    <>
      <NewFeatureTitleProgressCard
        student={student}
        homePageVariant={homePageVariant}
      />
      {homePageVariant ? null : <FeatureMilestonesCards student={student} />}
    </>
  );
};
