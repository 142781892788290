import { ReactElement } from 'react';

import { styled } from '@fis/util-theme';
import { CanvasMilestone } from '@fis/data-graphql';

import { MilestoneListNode } from './components';

const MilestoneListContainer = styled('ul', {
  padding: 0,
});

const NodeContainer = styled('li', {
  marginBottom: '$7',
  listStyle: 'none',
});

export const MilestoneList = ({
  milestoneNodeList,
}: {
  milestoneNodeList: CanvasMilestone[];
}): ReactElement => {
  const currentMilestone = milestoneNodeList.find(
    (milestoneNode) => !milestoneNode.completedAt
  );

  const nodes = milestoneNodeList.map((milestoneNode, i) => {
    const milestoneListNode = (
      <MilestoneListNode
        data={milestoneNode}
        current={currentMilestone?.id === milestoneNode.id}
        previousNode={i != 0 ? milestoneNodeList[i - 1] : null}
      />
    );

    if (!milestoneListNode) return null;

    return (
      <NodeContainer key={milestoneNode.id}>{milestoneListNode}</NodeContainer>
    );
  });

  return <MilestoneListContainer>{nodes}</MilestoneListContainer>;
};

export default MilestoneList;
