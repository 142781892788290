const cutOffFor15MonthFlexLength = new Date('2022-01-29T00:00:00+00:00');

const ensureDateFormat = (date: string | undefined): Date | null => {
  if (!date) return null;

  return new Date(date);
};

export const formatCohortCompletionDate = ({
  startDate,
  endDate,
}: {
  startDate: string | undefined;
  endDate: string | undefined;
}) => {
  const parsedStartDate = ensureDateFormat(startDate);
  const parsedEndDate = ensureDateFormat(endDate);

  if (!parsedStartDate) {
    return null;
  }

  if (parsedStartDate > cutOffFor15MonthFlexLength) {
    return parsedEndDate?.toISOString() || null;
  } else {
    // See https://flatiron.atlassian.net/browse/SM-44 for context
    // This part of the contional can be removed after May 2023 (once all cohorts starting before useCorrectEndDate have had 15 months on the platform)
    return new Date(
      parsedStartDate.setMonth(parsedStartDate.getMonth() + 15)
    ).toISOString();
  }
};
