import { ArrowRight } from 'phosphor-react';

import { styled } from '@fis/util-theme';
import { Heading } from '@fis/ui-heading';
import { Badge, BadgeRow } from '@fis/ui-badge';
import { Button } from '@fis/ui-buttons';
import { ResponsiveFlexContainer } from '@fis/ui-responsive-flex-container';

import {
  formatCohortCompletionDate,
  formatDate,
  formatTimeLeft,
} from '@fis/ui-format';

import { CanvasMilestone } from '@fis/data-graphql';

import { MilestonesInfo } from './milestonesInfo/milestonesInfo';

const PageTitleWrapper = styled('div', {
  maxWidth: '80rem',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '$7',
  width: '100%',
});

const Card = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$gray800',
  borderRadius: '$3',
  width: '100%',
  maxWidth: '1440px',
  margin: '0 auto',
  padding: '1rem',

  '.lightMode &': {
    backgroundColor: '$gray100',
  },
});

const StyledBadge = styled(Badge, {
  color: '$lightblue300',
});

const Metadata = styled('span', {
  fontWeight: '$bold',

  variants: {
    emphasis: {
      true: {
        color: '$yellow400',

        '.lightMode &': {
          color: '$yellow500',
        },
      },
    },
    deprecated: {
      true: {
        color: 'white',
      },
    },
  },
});

const getCardTitle = (
  userFirstName: string | null,
  completedMilestones: number,
  totalMilestones: number,
  homePageVariant?: boolean
) => {
  if (homePageVariant) {
    return `Welcome${userFirstName ? ', ' + userFirstName : ''}!`;
  } else if (totalMilestones === 0) {
    return `No milestones to display`;
  } else {
    return `${completedMilestones} of ${totalMilestones} milestones completed`;
  }
};

type ProgressCardPropTypes = {
  userFirstName: string | null;
  completedMilestones: number;
  totalMilestones: number;
  overdueMilestones: number;
  cohort?: {
    startDate: string;
    endDate: string;
  } | null;
  homePageVariant?: boolean;
  milestones?: CanvasMilestone[];
};

export const TitleProgressCard = (props: ProgressCardPropTypes) => {
  const {
    userFirstName,
    completedMilestones,
    overdueMilestones,
    totalMilestones,
    cohort,
    homePageVariant,
    milestones,
  } = props;

  const endDate = cohort && formatDate(formatCohortCompletionDate(cohort));
  if (!endDate) return null;
  return (
    <PageTitleWrapper>
      <ResponsiveFlexContainer flexColumn flexGap={4}>
        <ResponsiveFlexContainer align="center" justify="space-between">
          <Heading styling="xxl">
            {getCardTitle(
              userFirstName,
              completedMilestones,
              totalMilestones,
              homePageVariant
            )}
          </Heading>
          {homePageVariant ? (
            <Button as="a" href={'/courses'} secondary size="medium">
              View All Courses
              <ArrowRight size={16} weight="bold" />
            </Button>
          ) : null}
        </ResponsiveFlexContainer>
        <BadgeRow>
          <StyledBadge type="default">
            <Metadata emphasis>{`${formatTimeLeft(
              formatCohortCompletionDate(cohort)
            )} to go!`}</Metadata>
          </StyledBadge>
          <StyledBadge type="default">
            <span>Cohort end:</span>
            <Metadata>{endDate}</Metadata>
          </StyledBadge>
        </BadgeRow>
      </ResponsiveFlexContainer>
      {homePageVariant && (
        <Card>
          <MilestonesInfo
            overdueMilestones={overdueMilestones}
            completedMilestones={completedMilestones}
            totalMilestones={totalMilestones}
            cohort={cohort}
            milestones={milestones}
          />
        </Card>
      )}
    </PageTitleWrapper>
  );
};
