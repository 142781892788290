import { calculateRelativeTime } from '@fis/util-date-formatter';
import type { TimeFormatter } from '../types';

// formatTimeLeft(new Date());
export const formatTimeLeft: TimeFormatter<Intl.RelativeTimeFormatOptions> = (
  input,
  opts
) => {
  const rtf = new Intl.RelativeTimeFormat('en-US', {
    localeMatcher: 'best fit',
    numeric: 'always',
    style: 'long',
    ...opts,
  });

  const time = calculateRelativeTime(input, 'weeks');

  if (!time) return null;

  const parts = rtf.formatToParts(time.diff, time.unit);

  if (time.diff < 0) {
    return false;
  }

  return parts ? `${parts[1].value}${parts[2].value}` : null;
};
