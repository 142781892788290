import { ReactElement } from 'react';
import { CircleWavyWarning } from 'phosphor-react';
import PropTypes from 'prop-types';
import { UiLink } from '@fis/ui-link';

import { styled, theme } from '@fis/util-theme';

import { PAST_DUE, PREV_LATE } from '../../../../../../../../../statuses';

const Container = styled('div', {
  position: 'relative',
});

const TextContainer = styled('div', {
  display: 'block',
  padding: '$4',
  borderRadius: '$1',

  variants: {
    status: {
      [PAST_DUE]: {
        backgroundColor: '$tintDark8',
        color: '$red200',

        '.lightMode &': {
          backgroundColor: '$red50',
          color: '$red700',
        },
      },
      [PREV_LATE]: {
        backgroundColor: '$yellow800',
      },
    },
  },
});

const Text = styled('span', {
  fontSize: '$20',
  paddingLeft: '$10',
});

const StyledLink = styled(UiLink, {
  fontWeight: '700',
  color: '$lightblue400',
  textDecoration: 'underline',
  cursor: 'pointer',
});

const BoldText = styled('span', {
  fontWeight: '700',
  fontSize: '$20',
});

const getWarningIcon = (color: string) => (
  <CircleWavyWarning
    size={21}
    color={color}
    weight="fill"
    style={{
      position: 'absolute',
      top: theme.space[3].value, // 2 px less then padding
    }}
  />
);

export const StyledBanner = ({
  educatorAvailabilityScheduleUrl,
  pastDue,
  currentDaysLeft,
}: PropTypes.InferProps<typeof propTypes>): ReactElement | null => {
  let BannerContent = null;

  if (pastDue) {
    const ScheduleAOneOnOne = educatorAvailabilityScheduleUrl ? (
      <StyledLink
        href={educatorAvailabilityScheduleUrl}
        linkText="schedule a 1-1"
      />
    ) : (
      'schedule a 1-1'
    );

    BannerContent = (
      <TextContainer status={PAST_DUE}>
        {getWarningIcon(theme.colors.red300.value)}
        <Text>
          Please&nbsp;
          {ScheduleAOneOnOne}
          &nbsp;with your instructor to get back on track.
        </Text>
      </TextContainer>
    );
  } else if (currentDaysLeft) {
    const DeadlineText = (
      <BoldText>
        {currentDaysLeft === 1 ? 'by tomorrow' : `in ${currentDaysLeft} days`}
      </BoldText>
    );

    BannerContent = (
      <TextContainer status={PREV_LATE}>
        {getWarningIcon(theme.colors.yellow400.value)}
        <Text>
          You completed the previous milestone late! Complete this milestone{' '}
          {DeadlineText} to get back on pace.
        </Text>
      </TextContainer>
    );
  } else {
    console.error('<StyledBanner /> received unexpected props');
  }

  return <Container>{BannerContent}</Container>;
};

const propTypes = {
  educatorAvailabilityScheduleUrl: PropTypes.string,
  pastDue: PropTypes.bool,
  currentDaysLeft: PropTypes.number,
};

StyledBanner.propTypes = propTypes;

export default StyledBanner;
