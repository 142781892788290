import { styled } from '@fis/util-theme';
import Link from 'next/link';

const LinkText = styled('a', {
  color: '$blue400',
  fontWeight: '$bold',
  transitionDuration: '150ms',
  maxWidth: '600px',
  width: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textDecoration: 'none',
  overflow: 'hidden',
  minWidth: '0',
  borderRadius: '$1',

  '&:focus': {
    boxShadow: '$focusRing',
    outline: 'none',
  },

  // sets focus style for webkit browsers
  '@supports selector(:focus-visible)': {
    // negates :focus rule for browsers that do not support :focus-visible selector
    '&:focus': {
      boxShadow: 'none',
      outline: 'none',
    },

    '&:focus-visible': {
      boxShadow: '$focusRing',
      outline: 'none',
    },
  },

  '&:hover': {
    color: '$blue200',
    textDecoration: 'underline',
  },

  '.lightMode &': {
    color: '$blue700',

    '&:hover': {
      color: '$blue500',
    },
  },
});

export function UiLink(props: {
  linkText: string;
  href: string;
  openInNewTab?: boolean; // defaults to false
  internal?: boolean; // defaults to false. use if if linking to pages within the app within the user's current tab.
  stopPropagation?: boolean; // defaults to false
}) {
  const { linkText, href, openInNewTab, internal, stopPropagation } = props;

  return internal === true ? (
    <Link href={href} passHref>
      <LinkText>{linkText}</LinkText>
    </Link>
  ) : (
    <LinkText
      href={href}
      target={openInNewTab === true ? '_blank' : '_self'}
      rel={openInNewTab === true ? 'noopener noreferrer' : ''}
      onClick={
        stopPropagation === true ? (e) => e.stopPropagation() : undefined
      }
    >
      {linkText}
    </LinkText>
  );
}

export default UiLink;
