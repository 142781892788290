import { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { CheckCircle } from 'phosphor-react';

import { styled, theme } from '@fis/util-theme';
import { Heading } from '@fis/ui-heading';
import { COMPLETED, PAST_DUE, PREV_LATE } from '../../../../../../../statuses';
import Link from 'next/link';

const StyledTitle = styled(Heading, {
  fontSize: '$60',
  fontWeight: '700',
  variants: {
    status: {
      [PAST_DUE]: {},
      [PREV_LATE]: {},
      [COMPLETED]: {
        margin: 0,
        fontSize: '$30',
        display: 'inline-block',
        opacity: '0.5',
      },
    },
  },
});

const TitleContainer = styled('a', {
  display: 'flex',
  alignItems: 'center',
  gap: '$7',

  variants: {
    status: {
      [PAST_DUE]: {},
      [COMPLETED]: {
        height: '100%',
      },
      [PREV_LATE]: {},
    },
  },
});

const IconContainer = styled('div', {
  minWidth: '$10',
});

export const Title = ({
  htmlUrl,
  status,
  completed,
  children,
}: PropTypes.InferProps<typeof propTypes>): ReactElement => {
  const CheckCircleElement = completed ? (
    <CheckCircle
      size={32}
      color={theme.colors.teal400.value}
      weight="duotone"
    />
  ) : null;

  return (
    <Link href={htmlUrl} passHref>
      <TitleContainer status={status}>
        {completed && <IconContainer>{CheckCircleElement}</IconContainer>}
        <StyledTitle status={status} styling="med">
          {children}
        </StyledTitle>
      </TitleContainer>
    </Link>
  );
};

export default Title;

const propTypes = {
  status: PropTypes.oneOf([COMPLETED, PAST_DUE, PREV_LATE, undefined] as const),
  completed: PropTypes.bool,
  children: PropTypes.node.isRequired,
  htmlUrl: PropTypes.string.isRequired,
};
