import { ReactElement } from 'react';
import { useEffect } from 'react';

import { useGetMyEducatorsLazyQuery } from '@fis/data-graphql';
import { lastEducatorWithAvailabilitySchedule } from '@fis/util-availability-schedule-url';

import { StyledBanner } from './components';

export const PastDueBanner = (): ReactElement => {
  const [UseGetMyEducators, { data }] = useGetMyEducatorsLazyQuery();

  useEffect(() => {
    UseGetMyEducators();
  }, []);

  const educators = data?.student.educators;
  const educator = lastEducatorWithAvailabilitySchedule(educators);
  const url = educator?.availabilityScheduleUrl;

  return <StyledBanner educatorAvailabilityScheduleUrl={url} pastDue />;
};

export default PastDueBanner;
