import { styled } from '@fis/util-theme';

export const BadgeRow = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$3',
});

export const Badge = styled('div', {
  alignItems: 'baseline',
  backgroundColor: '$tintDark8',
  border: '1px solid $gray700',
  borderRadius: '$2',
  color: 'white',
  display: 'inline-flex',
  fontSize: '$20',
  fontWeight: '$medium',
  gap: '$4',
  lineHeight: '$500',
  padding: '$3',

  '.lightMode &': {
    borderColor: '$gray300',
    backgroundColor: '$gray100',
    color: '$gray700',
  },

  variants: {
    type: {
      caution: {
        backgroundColor: '$yellow800',
        border: '1px solid $yellow700',
        color: '$yellow100',

        '.lightMode &': {
          borderColor: '$yellow300',
          backgroundColor: '$yellow100',
          color: '$yellow800',
        },
      },
      error: {
        backgroundColor: '$red700',
        border: '1px solid $red500',
        boxShadow: '0px 0px 12px rgba(202, 24, 68, 1)',
        color: '$red100',
        fontWeight: '$bold',

        '.lightMode &': {
          borderColor: '$red300',
          backgroundColor: '$red50',
          color: '$red700',
          boxShadow: '0px 2px 12px rgba(255, 0, 0, 0.3);',
        },
      },
      success: {
        backgroundColor: '$teal800',
        border: '1px solid $teal600',
        color: '$teal200',

        '.lightMode &': {
          borderColor: '$teal400',
          backgroundColor: '$teal100',
          color: '$teal700',
        },
      },
      content: {
        border: '1px solid $gray600',
        backgroundColor: '$gray800',
        color: '$gray300',
        boxShadow: '$2',
      },
      viewed: {
        border: '1px solid $gray600',
        backgroundColor: '$gray800',
        color: '$purple300',
        boxShadow: '$2',

        '& svg': {
          color: '$purple600',
        },
      },
      default: {
        border: '1px solid $gray600',
        backgroundColor: '$tintDark8',
        color: '$gray300',

        '.lightMode &': {
          borderColor: '$gray300',
          backgroundColor: '$gray100',
          color: '$gray600',
        },
      },
    },
    hasIcon: {
      left: {
        alignItems: 'center',
        justifyContent: 'center',
        gap: '$3',
      },
    },
    size: {
      sm: {
        padding: '$2',
        borderRadius: '$1',
      },
    },
  },
});
