import { ReactElement } from 'react';

import { StyledBanner } from './components';

export const PrevLateBanner = ({
  daysLeft,
}: {
  daysLeft: number;
}): ReactElement => {
  return <StyledBanner currentDaysLeft={daysLeft} />;
};

export default PrevLateBanner;
