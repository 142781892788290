import { formatDate } from '@fis/ui-format';

import { Badge } from '@fis/ui-badge';
import { getStartOfDay } from '../../../../../../../util';

const getStatus = (
  status: 'COMPLETED' | 'PAST_DUE' | 'PREV_LATE' | undefined
) => {
  if (status === 'COMPLETED') {
    return 'success';
  } else if (status === 'PAST_DUE') {
    return 'error';
  } else if (status === 'PREV_LATE') {
    return 'caution';
  } else {
    return 'default';
  }
};

type DueDateBadgeProps = {
  status: 'COMPLETED' | 'PAST_DUE' | 'PREV_LATE' | undefined;
  dueOn: string;
  daysPastDue: number;
  pastDue: boolean;
  completedAt: string;
};

export const DueDateBadge = ({
  status,
  dueOn,
  daysPastDue,
  pastDue,
  completedAt,
}: DueDateBadgeProps) => {
  const text = completedAt
    ? `Completed: ${formatDate(getStartOfDay(completedAt))}`
    : pastDue
    ? `${daysPastDue} days past due`
    : `Due: ${formatDate(getStartOfDay(dueOn))}`;

  return <Badge type={getStatus(status)}>{text}</Badge>;
};
