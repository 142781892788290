type DateInput = Date | number | string | null | undefined;
type UnitTypes =
  | 'years'
  | 'months'
  | 'weeks'
  | 'days'
  | 'hours'
  | 'minutes'
  | 'seconds';

interface IcalcTimeDifference {
  (timestamp_1: number, timestamp_2: number, maxUnit: UnitTypes): {
    diff: number;
    unit: UnitTypes;
  };
}

export const calcTimeDifference: IcalcTimeDifference = (
  timestamp_1,
  timestamp_2,
  maxUnit
) => {
  let time = timestamp_2 - timestamp_1;
  const mod = time < 0;

  // Convert to seconds
  time = Math.abs(time) / 1000;

  // Define diff and units - Default to seconds
  let diff = time;
  let unit: UnitTypes = 'seconds';

  searching_for_unit: {
    // If there are minutes
    if (time > 60) {
      diff = time / 60;
      unit = 'minutes';
      if (maxUnit === 'minutes') break searching_for_unit;
    }

    // If there are hours
    if (time > 60 * 60) {
      diff = time / (60 * 60);
      unit = 'hours';
      if (maxUnit === 'hours') break searching_for_unit;
    }

    // If there are days
    if (time > 60 * 60 * 24) {
      diff = time / (60 * 60 * 24);
      unit = 'days';
      if (maxUnit === 'days') break searching_for_unit;
    }

    // If there are weeks
    if (time > 60 * 60 * 24 * 7) {
      diff = time / (60 * 60 * 24 * 7);
      unit = 'weeks';
      if (maxUnit === 'weeks') break searching_for_unit;
    }

    // If there are months
    if (time > 60 * 60 * 24 * 30) {
      diff = time / (60 * 60 * 24 * 30);
      unit = 'months';
      if (maxUnit === 'months') break searching_for_unit;
    }

    // If there are years
    if (time > 60 * 60 * 24 * 365) {
      diff = time / (60 * 60 * 24 * 365);
      unit = 'years';
      if (maxUnit === 'years') break searching_for_unit;
    }
  }

  const roundedDiff = Math.floor(diff);

  return {
    diff: mod ? roundedDiff : roundedDiff * -1,
    unit,
  };
};

interface IcalculateRelativeTime {
  (
    input: DateInput,
    maxUnit?: UnitTypes,
    now?: DateInput
  ): ReturnType<IcalcTimeDifference> | null;
}

export const calculateRelativeTime: IcalculateRelativeTime = (
  input,
  maxUnit = 'years',
  now = new Date()
) => {
  if (!input) {
    return null;
  }

  // Parse Input
  if (typeof input === 'string') {
    input = Date.parse(input);
  } else if (input instanceof Date) {
    input = input.getTime();
  } // else typeof input === 'number'

  // Parse "Now"
  if (typeof now === 'string') {
    now = Date.parse(now);
  } else if (now instanceof Date) {
    now = now.getTime();
  } else if (typeof now !== 'number') {
    now = new Date().getTime();
  }

  return calcTimeDifference(input, now, maxUnit);
};
