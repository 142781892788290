import { ReactElement, useEffect } from 'react';

import { useGetMyEducatorsLazyQuery, CanvasMilestone } from '@fis/data-graphql';
import { lastEducatorWithAvailabilitySchedule } from '@fis/util-availability-schedule-url';

import { MilestonesInfoUI } from './components/milestonesInfoUI';
import { formatCohortCompletionDate } from '@fis/ui-format';
import { AlertCardStatesType, DANGER, DEFAULT } from '../../statuses';

const getOverallMilestoneCompletionStatus = (
  overdueMilestones: number
): AlertCardStatesType => {
  if (overdueMilestones > 3) {
    return DANGER;
  } else {
    return DEFAULT;
  }
};

interface milestonesInfoProps {
  cohort?: {
    startDate: string;
    endDate: string;
  } | null;
  totalMilestones: number;
  completedMilestones: number;
  overdueMilestones: number;
  milestones?: CanvasMilestone[];
}

export const MilestonesInfo = ({
  cohort,
  totalMilestones,
  completedMilestones,
  overdueMilestones,
  milestones,
}: milestonesInfoProps): ReactElement | null => {
  const [getMyEducators, { data }] = useGetMyEducatorsLazyQuery();

  useEffect(() => {
    getMyEducators();
  }, []);

  const educators = data?.student.educators;
  const educator = lastEducatorWithAvailabilitySchedule(educators);
  const availabilityScheduleUrl = educator?.availabilityScheduleUrl;

  const endDate = formatCohortCompletionDate(
    cohort as { startDate: string; endDate: string }
  );
  if (!endDate) return null;

  if (totalMilestones === 0) {
    return <span>No milestones to display</span>;
  }
  const currentMilestone = milestones?.find((milestone) => {
    return !milestone.completedAt;
  });

  return (
    <MilestonesInfoUI
      educatorScheduleUrl={availabilityScheduleUrl}
      cohortEndDate={endDate}
      completedMilestones={completedMilestones}
      totalMilestones={totalMilestones}
      currentMilestone={currentMilestone}
      alertCardState={getOverallMilestoneCompletionStatus(overdueMilestones)}
    />
  );
};

export default MilestonesInfo;
