import { styled } from '@fis/util-theme';
import { formatDate } from '@fis/ui-format';

import { AlertCardStatesType, DANGER, DEFAULT } from '../../../statuses';
import { AlertCard } from '../..';
import { MilestoneNode } from '../../milestoneList/components/milestoneListNode/components/milestoneNode/milestoneNode';
import { ReactElement } from 'react';
import { ArrowRight } from 'phosphor-react';
import { CanvasMilestone } from '@fis/data-graphql';

const Container = styled('div', {
  display: 'flex',
  gap: '$9',

  '@md': {
    flexDirection: 'column',
    gap: '0',
  },
});

const MilestoneNodeContainer = styled('div', {
  display: 'flex',
  flexGrow: 1,
  width: '100%',
  marginTop: '$9',
});

const AlertCardContainer = styled('div', {
  display: 'flex',
  flexGrow: 1,
  width: '100%',
  marginTop: '$9',
});

const StyledMilestoneNode = styled(MilestoneNode, {
  height: '100%',
});

const getAlertTitle = (
  state: string,
  completedMilestones: number,
  totalMilestones: number
) => {
  if (state === DEFAULT) {
    return `${completedMilestones} of ${totalMilestones} milestones complete`;
  }
  if (state === DANGER) {
    return 'You are more than 3 milestones overdue!';
  } else return;
};

const getAlertBody = (alertState: string, formattedCohortEndDate: string) => {
  if (alertState === DEFAULT) {
    return `Keep up the good work!`;
  }
  if (alertState === DANGER) {
    return `This program must be fully complete by ${formattedCohortEndDate}. Please meet with your instructor to make a plan for getting back on track.`;
  } else return;
};

const getButtonAttrs = ({
  alertCardState,
  educatorScheduleUrl,
}: {
  alertCardState: AlertCardStatesType;
  educatorScheduleUrl: string | null | undefined;
}): {
  buttonHref: string | null;
  buttonText: ReactElement | undefined;
} => {
  if (alertCardState === DANGER) {
    if (educatorScheduleUrl) {
      return {
        buttonHref: educatorScheduleUrl,
        buttonText: (
          <>
            Schedule a 1-1 <ArrowRight weight="bold" size="16" />{' '}
          </>
        ),
      };
    } else {
      return {
        buttonHref: null,
        buttonText: undefined,
      };
    }
  } else {
    return {
      buttonHref: '/milestones',
      buttonText: (
        <>
          Milestones <ArrowRight weight="bold" size="16" />
        </>
      ),
    };
  }
};

interface MilestoneInfoUIProps {
  educatorScheduleUrl: string | null | undefined;
  cohortEndDate: string;
  alertCardState: AlertCardStatesType;
  completedMilestones: number;
  totalMilestones: number;
  showCurrentMilestone?: boolean;
  currentMilestone?: CanvasMilestone | null;
}

export const MilestonesInfoUI = (props: MilestoneInfoUIProps) => {
  const {
    educatorScheduleUrl,
    cohortEndDate,
    alertCardState,
    completedMilestones,
    totalMilestones,
    currentMilestone,
  } = props;

  return (
    <Container>
      <AlertCardContainer>
        <AlertCard
          {...getButtonAttrs({ alertCardState, educatorScheduleUrl })}
          alertTitle={getAlertTitle(
            alertCardState,
            completedMilestones,
            totalMilestones
          )}
          alertBody={getAlertBody(
            alertCardState,
            formatDate(cohortEndDate) as string
          )}
          state={alertCardState}
        />
      </AlertCardContainer>
      {currentMilestone && (
        <MilestoneNodeContainer>
          <StyledMilestoneNode
            data={currentMilestone}
            current={true}
            allowBanner={false}
          />
        </MilestoneNodeContainer>
      )}
    </Container>
  );
};
