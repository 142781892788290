import PropTypes from 'prop-types';

export const PaceChangePropTypes = {
  __typename: PropTypes.oneOf(['PaceChangeNode']),
  uuid: PropTypes.string.isRequired,
  occurredAt: PropTypes.string.isRequired,
  oldPace: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  newPace: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export type PaceChangeType = PropTypes.InferType<typeof PaceChangePropTypes>;

export const MilestonePropTypes = {
  __typename: PropTypes.oneOf(['CanvasMilestone']),
  id: PropTypes.string,
  dueOn: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  completedAt: PropTypes.string,
  current: PropTypes.bool.isRequired,
  htmlUrl: PropTypes.string.isRequired,
};

export type MilestoneType = PropTypes.InferProps<typeof MilestonePropTypes>;

const PacedMilestonesListPropTypes = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.shape(MilestonePropTypes),
    PropTypes.shape(PaceChangePropTypes),
  ]).isRequired
).isRequired;

export type PacedMilestonesListType = PropTypes.InferProps<
  typeof PacedMilestonesListPropTypes
>;

export const NodeNames = {
  milestone: 'MilestoneNode',
};

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
