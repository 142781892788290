import { ReactElement } from 'react';

import { CanvasMilestone } from '@fis/data-graphql';

import { TitleProgressCard } from './components';

interface ProgressCardProps {
  homePageVariant?: boolean;
  student: {
    cohort?: {
      startDate?: string;
      endDate?: string;
    };
    firstName?: string;
    milestones: CanvasMilestone[];
  };
}

export const NewFeatureTitleProgressCard = ({
  homePageVariant,
  student,
}: ProgressCardProps): ReactElement | null => {
  const cohort = student.cohort;
  const milestones = student.milestones;

  const milestoneCount = milestones?.reduce(
    (
      acc: {
        completedMilestones: number;
        totalMilestones: number;
        overDueMilestones: number;
      },
      milestone
    ) => {
      if (milestone.completedAt) {
        return {
          ...acc,
          completedMilestones: acc.completedMilestones + 1,
          totalMilestones: acc.totalMilestones + 1,
        };
      } else if (
        !milestone.completedAt &&
        new Date(milestone.dueOn) < new Date()
      ) {
        return {
          ...acc,
          overDueMilestones: acc.overDueMilestones + 1,
          totalMilestones: acc.totalMilestones + 1,
        };
      } else {
        return { ...acc, totalMilestones: acc.totalMilestones + 1 };
      }
    },
    { completedMilestones: 0, totalMilestones: 0, overDueMilestones: 0 }
  );
  return (
    <TitleProgressCard
      userFirstName={student.firstName || null}
      completedMilestones={milestoneCount.completedMilestones}
      totalMilestones={milestoneCount.totalMilestones}
      overdueMilestones={milestoneCount.overDueMilestones}
      cohort={cohort as { startDate: string; endDate: string }}
      homePageVariant={homePageVariant}
      milestones={milestones}
    />
  );
};
