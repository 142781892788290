import { ReactElement } from 'react';
import { CircleWavyWarning } from 'phosphor-react';
import Link from 'next/link';

import { styled, theme } from '@fis/util-theme';
import { Button } from '@fis/ui-buttons';
import { Heading } from '@fis/ui-heading';

import { AlertCardStatesType, DANGER, DEFAULT } from './../statuses';

const AlertContainer = styled('div', {
  display: 'flex',
  borderRadius: '$2',
  flexGrow: 1,
  background: '$tintDark8',

  '.lightMode &': {
    background: 'white',
    boxShadow: '$2',
  },
});

const LeftBorder = styled('div', {
  padding: '14px 6px 0',
  minWidth: 'min-content',
  background: '$gray700',
  borderRadius: '$2 0 0 $2',

  '.lightMode &': {
    background: '$gray300',
  },

  variants: {
    alertState: {
      [DEFAULT]: {},
      [DANGER]: {
        background: '$red500',

        '.lightMode &': {
          background: '$red400',
        },
      },
    },
  },
});

const ContentInner = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$3',
  padding: '$7',
  minWidth: 0,
  flexGrow: 1,
});

const AlertBody = styled('div', {
  fontSize: '$20',
  color: '$gray200',
  minWidth: 0,
  lineHeight: '1.3rem',

  '.lightMode &': {
    color: '$gray500',
  },
});

const ButtonContainer = styled('div', {
  padding: '$7 $7 $7 0',
});

const getLeftIcon = (alertState: string) => {
  if (alertState === '' || alertState === DEFAULT) {
    return (
      <span
        role="img"
        aria-label="confetti ball"
        style={{ fontSize: '24px', textAlign: 'center', width: '100%' }}
      >
        🎊
      </span>
    );
  }
  if (alertState === DANGER) {
    return (
      <CircleWavyWarning
        size="28"
        color={theme.colors.red50.value}
        weight="fill"
      />
    );
  } else return;
};

type alertCardProps = {
  buttonHref?: string | null | undefined;
  buttonText?: ReactElement;
  alertTitle: string | undefined;
  alertBody: string | undefined;
  state: AlertCardStatesType;
};

export const AlertCard = (props: alertCardProps) => {
  const { buttonHref, buttonText, alertTitle, alertBody, state } = props;

  return (
    <AlertContainer>
      <LeftBorder alertState={state}>{getLeftIcon(state)}</LeftBorder>
      <ContentInner>
        <Heading styling="med">{alertTitle}</Heading>
        <AlertBody>{alertBody}</AlertBody>
      </ContentInner>
      {buttonHref && (
        <ButtonContainer>
          <Link href={buttonHref}>
            <Button secondary>{buttonText}</Button>
          </Link>
        </ButtonContainer>
      )}
    </AlertContainer>
  );
};
