import { ReactElement, useContext } from 'react';

import { CanvasMilestone as CanvasMilestoneType } from '@fis/data-graphql';

import { MilestoneNode } from './components';
import { MilestonesContext } from '../../../../contexts';

type MilestoneListNodeProps = {
  data: CanvasMilestoneType;
  previousNode: CanvasMilestoneType | null;
  current: boolean;
};

export const MilestoneListNode = ({
  data,
  previousNode,
  current,
}: MilestoneListNodeProps): ReactElement | null => {
  const { adminView } = useContext(MilestonesContext);
  return (
    <MilestoneNode
      current={current}
      data={data as CanvasMilestoneType}
      previousNode={previousNode}
      allowBanner={!adminView}
    />
  );
};

export default MilestoneListNode;
